<template>
  <div class="page-content">
    <div class="container">
      <h1>Shopping Cart</h1>
      <section class="order-section pl-2 pr-2 overflow-section" v-if="cart && cart.length">
        <div class="table-row mb-1" v-for="(val, index) in cart" :key="index">
          <div class="table-cell-img">
            <img
              :src="require(`@/assets/images/${val.img}`)"
              :alt="val.title"
              width="62"
            >
          </div>
          <div class="table-cell-text pr-1">
            <div class="title-cell">
              <b>{{ val.title }}</b>
              <b class="price">${{ val.price.toFixed(2) }}</b>
            </div>
            <div class="cell-options d-flex flex-column no-indent">
              <span v-if="val.typeOfBuild ==='sandwich'">{{ val.cookie }}</span>
              <span v-for="(flavor, i) in val.flavors" :key="'f'+i">{{ flavor }}</span>
              <span v-for="(milk, i) in val.milk" :key="'m'+i">{{ milk }}</span>
              <span v-for="(soda, i) in val.soda" :key="'s'+i">{{ soda }}</span>
              <span v-for="(topping, i) in val.toppings" :key="'t'+i" class="extra-line-item">
                + {{ topping.name }} <span class="price">${{ (topping.amount / 100).toFixed(2) }}</span>
              </span>
            </div>
          </div>
          <div class="control-box">
            <span @click="handleRemove(val.id)" class="close xs"></span>
          </div>
        </div>
        <div class="order-total">
          <h2 class="text-center">Price</h2>
          <hr class="divider-gray">
          <div class="row top-border">
            <div class="col-2">
              <span>Subtotal</span>
            </div>
            <div class="col-2 text-right">
              <span v-text="subTotal"></span>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              <span>Taxes</span>
            </div>
            <div class="col-2 text-right">
              <span v-text="tax"></span>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              <strong>Total</strong>
            </div>
            <div class="col-2 text-right">
              <strong v-text="total"></strong>
            </div>
          </div>
        </div>
        <div class="error-container">
          <div class="error">
            {{ error }}
          </div>
        </div>
        <div class="controls">
          <Router-link
            class="btn btn-outline mb-1"
            :to="$options.links.home"
          >
            Would you like to add more?
            </Router-link>
          <Router-link
            class="btn"
            :to="$options.links.checkout"
            v-if="canCheckout"
          >
            Check out
            </Router-link>
          <div class="btn" v-if="!canCheckout">
            <img
              :src="require('@/assets/images/loader-horizontal.svg')">
          </div>
        </div>
      </section>
      <section v-else>
        <p class="text-center"><b>Cart is empty</b></p>
        <div class="controls">
          <Router-link
            class="btn btn-outline mb-1"
            :to="$options.links.home"
          >
            Would you like to add more?
          </Router-link>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ROUTES from '@/constans/route';

export default {
  links: {
    home: ROUTES.HOME,
    checkout: ROUTES.CHECKOUT,
  },
  name: 'Cart',
  data: () => ({
    subTotal: '$0.00',
    tax: '$0.00',
    total: '$0.00',
    canCheckout: false,
    error: '',
  }),
  computed: {
    ...mapGetters(['cart']),
  },
  mounted() {
    const self = this;
    if (this.cart && this.cart.length > 0) {
      this.$store.dispatch('previewOrder')
        .then((resp) => {
          self.updateTotals(resp);
        });
    }
  },
  methods: {
    handleRemove(id) {
      const self = this;
      this.$store.commit('remove', {
        id,
        el: ['cart', 'builds'],
      });
      this.canCheckout = false;
      this.error = '';
      this.$store.dispatch('previewOrder')
        .then((resp) => {
          self.updateTotals(resp);
        });
    },
    updateTotals(resp) {
      if (resp.success) {
        const { order } = resp.result;
        const lineItems = order.line_items;
        let subtotal = 0;
        lineItems.forEach((lineItem) => {
          subtotal += lineItem.base_price_money.amount;
        });
        this.canCheckout = true;
        this.subTotal = `$${(subtotal / 100).toFixed(2)}`;
        this.tax = `$${(order.total_tax_money.amount / 100).toFixed(2)}`;
        this.total = `$${(order.total_money.amount / 100).toFixed(2)}`;
        this.$store.commit('saveTotal', order.total_money.amount / 100);
      } else {
        this.error = 'Oops, we ran into an error while calculating our total.';
      }
    },
  },
};
</script>

<style scoped>
.extra-line-item {
  display: flex;
  justify-content: space-between;
}
</style>
